import { useState, useRef, useEffect } from "react";
import { SlidersHorizontal, X } from "phosphor-react";
import React from "react";
import styles from "./Filter.module.scss";

import useOutOfFocus from "../../Hooks/useOutOfFocus";

const Filter = ({
  selectedFilter: selected,
  setSelectedFilter: setSelected,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const options = [
    {
      status: "SUCCESSFUL",
      key: "successful",
    },
    {
      status: "PENDING",
      key: "pending",
    },
    { status: "FAILED", key: "failed" },
  ];

  const filterRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(filterRef);

  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowOptions(false);
    }
  }, [isOutOfFocus]);

  return (
    <div className={styles.filter}>
      <button
        onClick={() => setShowOptions(true)}
        className={styles.filter__main}
      >
        {selected ? (
          <b
            onClick={() => {
              setSelected(null);
              setIsOutOfFocus(true);
            }}
          >
            <X size={20} />
          </b>
        ) : (
          <SlidersHorizontal size={20} />
        )}
        <span>
          {selected ? (
            <button
              style={
                selected?.status === "SUCCESSFUL"
                  ? { border: "1px solid #39A648", color: "#39A648" }
                  : selected?.status === "FAILED"
                  ? { border: "1px solid #E10000", color: "#E10000" }
                  : { border: "1px solid #4438CB", color: "#4438CB" }
              }
            >
              {selected?.status}
            </button>
          ) : (
            `Filter`
          )}
        </span>
      </button>
      {showOptions && (
        <div ref={filterRef} className={styles.filter__options}>
          {options?.map((option, index) => (
            <button
              onClick={() => {
                setSelected({ ...option });
                setIsOutOfFocus(true);
                console.log(index, options[index]);
              }}
              style={
                option?.status === "SUCCESSFUL"
                  ? { border: "1px solid #39A648", color: "#39A648" }
                  : option?.status === "FAILED"
                  ? { border: "1px solid #E10000", color: "#E10000" }
                  : { border: "1px solid #4438CB", color: "#4438CB" }
              }
            >
              {option?.status}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;
