import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CaretLeft, WarningCircle, Copy } from "phosphor-react";
import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Pay.module.scss";

//components
import Button from "../../../components/Button/Button";

//images
import EthereumLogo from "../../../assets/icons/ethereum.png";
import BinanceLogo from "../../../assets/icons/binance.png";
import BitcoinLogo from "../../../assets/icons/bitcoin.png";
import PolygonLogo from "../../../assets/icons/polygon.png";
import BSCNETWORKLogo from "../../../assets/icons/bsc-network.jpg";
import BNBLogo from "../../../assets/icons/bnb.jpg";
import Busd from "../../../assets/icons/busd.png";
import Usdc from "../../../assets/icons/usdc.png";
import Dai from "../../../assets/icons/dai.jpg";
import USDT_BSC from "../../../assets/icons/usdt-bsc.jpg";
import USDT_OTHER from "../../../assets/icons/usdt-other.jpg";

//components

const Pay = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [payload, setPayload] = useState();
  const icons = {
    Ethereum: EthereumLogo,
    ETH: EthereumLogo,
    BSC: BSCNETWORKLogo,
    BNB: BNBLogo,
    Bitcoin: BitcoinLogo,
    BTC: BitcoinLogo,
    MATIC: PolygonLogo,
    Polygon: PolygonLogo,
    BUSD: Busd,
    USDC: Usdc,
    DAI: Dai,
    USDT_BSC_MAINNET: USDT_BSC,
    USDT: USDT_OTHER,
  };

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);

    toast.success("Address copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const _payload = searchParams.get("payload");

    if (_payload) {
      try {
        setPayload(JSON.parse(_payload));
      } catch (error) {
        navigate(-1);
        console.log(error);
      }
      return;
    }
    navigate(-1);
  }, []);

  return (
    <div className={styles.pay}>
      <div className={styles.pay__heading}>
        <div onClick={() => navigate(-1)} className={styles.pay__icon}>
          <CaretLeft size={25} color={"#000"} weight="bold" />
        </div>
        <h3>Make Payment</h3>
      </div>

      <div className={styles.pay__amount}>
        {/* <h2 className={styles.pay__amount__naira}>
          <CurrencyNgn weight="bold" color="#5C5C5C" size={32} />
          <span>{Number(payload?.amount ?? 0).toLocaleString()}</span>
        </h2> */}

        <img
          className={styles.pay__amount__crypto__img}
          alt="coin logo"
          src={icons[payload?.activeCoinShort] ?? ""}
        />
        <h4 className={styles.pay__amount__crypto}>
          {/* N{Number(payload?.amount).toLocaleString()} is equivalent to{" "} */}
          {payload?.coinAmount} {payload?.coinValue?.coin}
        </h4>
        <p className={styles.pay__amount__rate}>
          1{payload?.coinValue?.coin} ~{" "}
          {(Number(payload?.exchangeRate) / Number(payload?.coinToUsd)).toFixed(2)}
          {payload?.localCurrency}
        </p>
        <div className={styles.pay__qrCode}>
          <QRCodeSVG level="H" size={220} value={payload?.address ?? "#"} />
        </div>
        <div
          onClick={() => handleCopy(payload?.address)}
          className={styles.pay__link}
        >
          <span>{`${payload?.address.slice(0, 9)}...${payload?.address.slice(
            -5
          )}`}</span>
          {/* <Copy size={25} color="#000" /> */}
        </div>
        {/* <img src={QrCode} alt="qr code to send money" /> */}
        {/* <h4 className={styles.pay__amount__crypto}>
          N{Number(payload?.amount).toLocaleString()} is equivalent to{" "}
          {payload?.coinValue?.number_of_coins} {payload?.coinValue?.coin}
        </h4> */}
      </div>

      <div className={styles.pay__networks}>
        <div className={styles.pay__network}>
          <img src={icons[payload?.activeNetwork] ?? ""} alt="network logo" />
          <p>{payload?.activeNetwork}</p>
        </div>
        <div className={styles.pay__network}>
          <img src={icons[payload?.activeCoinShort] ?? ""} alt="coin logo" />
          <p>{payload?.activeCoin}</p>
        </div>
      </div>

      <div className={styles.pay__warning}>
        <WarningCircle size={15} weight="bold" color="#E16C00" />
        <p>Make sure you send from selected network </p>
      </div>

      <div
        onClick={() =>
          navigate(
            `/dashboard/verify?id=${payload?.id}&amount=${payload?.amount}&coin=${payload?.activeCoin}&coinValue=${payload?.coinAmount}`
          )
        }
        className={styles.pay__proceed}
      >
        <Button text="Payment Made" />
      </div>
    </div>
  );
};

export default Pay;
