import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams , Link} from "react-router-dom";
import Button from "../../../components/Button/Button";
import { CaretLeft } from "phosphor-react";
import ReactLoading from "react-loading";
import { useQuery } from "react-query";
import { getTransaction } from "../../../services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Verify.module.scss";

//components
import Failed from "./Failed";
import Success from "./Success";

const Verify = ({ data }) => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState({});
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState("PENDING");

  //createTransactionInvoice
  const {} = useQuery(
    ["get transaction status", payload],
    () => getTransaction(payload?.id),
    {
      refetchInterval:
        status === "SUCCESSFUL" || status === "FAILED" ? 0 : 5000,
      onSuccess: (data) => {
        // console.log(data, "transaction data");
        setStatus(data?.status);
      },
      onError: (error) => {
        console.log({ error });
        navigate(-1);
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  useEffect(() => {
    const id = searchParams.get("id");
    const amount = searchParams.get("amount");
    const coin = searchParams.get("coin");
    const coinValue = searchParams.get("coinValue");
    if (id && amount && coin && coinValue) {
      try {
        const _payload = {
          id,
          amount,
          coin,
          coinValue,
        };

        setPayload(_payload);
      } catch (error) {
        navigate(-1);
      }
      return;
    }

    navigate(-1);
  }, []);

  return (
    <>
      {status == "PENDING" && (
        <div className={styles.verify}>
          <div className={styles.verify__heading}>
            <div onClick={() => navigate(-1)} className={styles.verify__icon}>
              <CaretLeft size={25} color={"#000"} weight="bold" />
            </div>
            <h3>Make Payment</h3>
          </div>

          <div className={styles.verify__verify__box}>
            <div className={styles.verify__verify}>
              <ReactLoading
                type="cylon"
                width={120}
                height={120}
                color="#4438CB"
              />
              <h4>Verifying payment</h4>
            </div>
          </div>

          <div className={styles.verify__proceed}>
            <Link to="/dashboard">
              <Button text="Go home" />
            </Link>
          </div>
        </div>
      )}
      {status === "FAILED" && (
        <Failed
          amount={payload?.amount}
          coin={payload?.coin}
          coinValue={payload?.coinValue}
          currency={data?.currency}
        />
      )}
      {status === "SUCCESSFUL" && (
        <Success
          amount={payload?.amount}
          coin={payload?.coin}
          coinValue={payload?.coinValue}
          currency={data?.currency}
        />
      )}
    </>
  );
};

export default Verify;
