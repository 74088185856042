import React, { useState, useEffect } from "react";
import { useNavigate , Link } from "react-router-dom";
import { CaretLeft, CheckCircle, CurrencyNgn } from "phosphor-react";
import styles from "./Verify.module.scss";

//components
import Button from "../../../components/Button/Button";

const MakePayment = ({ amount, coin , coinValue , currency }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.verify}>
      <div className={styles.verify__heading}>
        <div onClick={() => navigate(-1)} className={styles.verify__icon}>
          <CaretLeft size={25} color={"#000"} weight="bold" />
        </div>
        {/* <h3>Make Payment</h3> */}
      </div>

      <div className={styles.verify__success}>
        <CheckCircle size={152} weight="fill" color="#4438CB" />
      </div>

      <div className={styles.verify__amount}>
        <h2 className={styles.verify__amount__naira}>
          {/* <CurrencyNgn weight="bold" color="#5C5C5C" size={32} /> */}
          <span>{currency?.symbol}{Number(amount ?? 0)?.toLocaleString()}</span>
        </h2>
        <h4 className={styles.verify__amount__crypto}>{coinValue} {coin}</h4>
      </div>

      <h4 className={styles.verify__success__message}>Payment successful</h4>

      <div
      
        className={styles.verify__proceed}
      >
        <Link to="/dashboard">
        <Button text="Go home" />
        </Link>
      </div>
    </div>
  );
};

export default MakePayment;
