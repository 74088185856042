import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { userLoginFinalize } from "../../services";
import { CaretLeft } from "phosphor-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./VerifyPhone.module.scss";

//components
import Keyboard from "../../components/Keyboard/Keyboard";

//components
import Button from "../../components/Button/Button";
import InputPin from "../../components/InputPin/InputPin";

const VerifyPhone = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [otpIdentifier, setOtpIdentifier] = useState("");

  const { isLoading, mutate: _userLoginFinalize } = useMutation(
    ["user login finalize"],
    (payload) => userLoginFinalize(payload),
    {
      onSuccess: (data) => {
        localStorage.setItem("knit-token", data?.access_token);
        navigate("/dashboard");
        console.log(data);
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  useEffect(() => {
    const otp_identifier = searchParams.get("otp_identifier");
    if (otp_identifier) {
      setOtpIdentifier(otp_identifier);
      return;
    }
    navigate("/auth/login");
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("knit-token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleVerification = () => {
    if (!content || content?.length !== 6) {
      toast.error("Kindly provided a valid OTP code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    _userLoginFinalize({
      code: Number(content),
      otp_identifier: otpIdentifier,
    });
  };


  useEffect(() => {
    if(content?.length === 6){
      handleVerification()
    }
   
  }, [content])
  
  return (
    <>
      <div className={styles.verifyPhone}>
        <div className={styles.verifyPhone__heading}>
          <div
            onClick={() => navigate(-1)}
            className={styles.verifyPhone__icon}
          >
            <CaretLeft size={25} color={"#000"} weight="bold" />
          </div>
          <h3>Verify phone number</h3>
        </div>

        <p className={styles.verifyPhone__desc}>
          Please provide the 6 digit code sent to your phone number/email
        </p>

        <div className={styles.verifyPhone__inputPin}>
          <InputPin value={content} setValue={setContent} />
        </div>
        <p className={styles.verifyPhone__resend}>
          Didn’t receive code? <b>Request again</b>
        </p>
        <p className={styles.verifyPhone__call}>
          <b>Get via call</b>
        </p>

        <div className={styles.verifyPhone__keyboard}>
          <Keyboard content={content} setContent={setContent} limit={6} />
        </div>

        <div className={styles.verifyPhone__login}>
          <Button
            onClick={handleVerification}
            text={
              isLoading ? (
                <ReactLoading type="spin" width={32} height={32} />
              ) : (
                "Verify"
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default VerifyPhone;
