import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CaretLeft } from "phosphor-react";
import { useQuery } from "react-query";
import dateFormat from "dateformat";
import { getTransactions } from "../../../services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Transactions.module.scss";

//components
import DatePicker from "../../../components/DatePicker/DatePicker";
import Transaction from "../../../components/Transaction/Transaction";
import Filter from "../../../components/Filter/Filter";

//hooks
import useOutOfFocus from "../../../Hooks/useOutOfFocus";

const Transactions = ({ data }) => {
  const navigate = useNavigate();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [pickerSelected, setPickerSelected] = useState(false);
  const [date, setDate] = useState();
  const [selectedFilter, setSelectedFilter] = useState();
  // const [transactions, setTransactions] = useState([]);
  const datePickerRef = useRef(null);

  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(datePickerRef);
  useEffect(() => {
    console.log(data, "data");
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setOpenDatePicker(false);
    }
  }, [isOutOfFocus]);

  const { isLoading: isLoadingTransactions, data: transactions } = useQuery(
    ["get transactions", selectedFilter],
    () => getTransactions(selectedFilter),
    {
      onSuccess: (data) => {
        console.log(data);
        // setTransactions(data);
      },
      onError: (error) => {
        console.log({ error }, "error");
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  return (
    <div className={styles.transactions}>
      <div className={styles.transactions__heading}>
        <div onClick={() => navigate(-1)} className={styles.transactions__icon}>
          <CaretLeft size={25} color={"#000"} weight="bold" />
        </div>
        <h3>Transactions</h3>
      </div>

      <div className={styles.transactions__actions}>
        <div className={styles.transactions__filter}>
          <Filter
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </div>

        <div
          style={openDatePicker ? { width: "100%", marginLeft: "auto" } : {}}
          className={styles.transactions__datePicker__container}
        >
          <button
            onClick={() => setOpenDatePicker(!openDatePicker)}
            className={styles.transactions__datePicker}
            style={
              pickerSelected
                ? {
                    background: "#4438CB",
                    border: "1px solid #4438CB",
                    color: "#fff",
                  }
                : {}
            }
          >
            {pickerSelected ? "Selected" : "Select date"}
          </button>
          {openDatePicker && !isOutOfFocus ? (
            <div
              ref={datePickerRef}
              className={styles.transactions__datePicker__menu}
            >
              <DatePicker
                setPickerSelected={setPickerSelected}
                setOpenDatePicker={setOpenDatePicker}
                setDate={setDate}
              />
            </div>
          ) : null}
        </div>
      </div>

      {isLoadingTransactions ? (
        <p className={styles.transactions__loading}>Please wait...</p>
      ) : transactions && transactions?.length > 0 ? (
        <>
          {/* december transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "December"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>December</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "December" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* November transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "November"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>November</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "November" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* october transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "October"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>October</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "October" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* september transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "September"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>September</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "September" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* August transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "August"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>August</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "August" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* july transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "July"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>July</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "July" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* june transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "June"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>June</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "June" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* may transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "May"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>May</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "May" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* April transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "April"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>April</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "April" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* march transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "March"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>March</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "March" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}
          {/* february transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "February"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>February</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "February" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}

          {/* january transactions */}
          {transactions?.find(
            (item) => dateFormat(item?.updatedAt, "mmmm") === "January"
          ) && (
            <div className={styles.transactions__group}>
              <h4 className={styles.transactions__group__heading}>January</h4>
              {transactions?.map((transaction, index) =>
                dateFormat(transaction?.updatedAt, "mmmm") === "January" ? (
                  <Transaction
                    key={index}
                    data={transaction}
                    currency={data?.currency}
                  />
                ) : null
              )}
            </div>
          )}
        </>
      ) : (
        <p className={styles.transactions__empty}>
          You currently do not have any transaction history
        </p>
      )}
    </div>
  );
};

export default Transactions;
