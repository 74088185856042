import { Http } from "../utils/http";

export const userLogin = (payload) => Http.post(`/auth/login`, payload);

export const userLoginFinalize = (payload) =>
  Http.post(`/auth/login/finalize`, payload);

export const generateLink = (payload) =>
  Http.post(`/transactions/create/link`, payload);

export const userDetails = () => Http.get(`/auth/profile`);

export const createTransactionInvoice = (payload) =>
  Http.post("/transactions", payload);

export const getTransactions = (status) =>
  Http.get(`/transactions?status=${status?.key ?? ""}`);

export const getTransaction = (id) => Http.get(`/transactions/${id}`);

export const getCoinValueForLocalCurrency = (
  local_currency,
  local_amount,
  coin,
  user_id
) =>
  Http.get(
    `/transactions/${local_currency}/${local_amount}/convert/${coin}/${
      user_id ?? ""
    }`
  );
