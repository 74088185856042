import React from "react";
import styles from "./Button.module.scss";

const Button = ({ text  , ...props}) => {
  return (
    <button {...props} className={styles.button}>
      {text}
    </button>
  );
};

export default Button;
