import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import dateFormat from "dateformat";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CaretLeft,
  CurrencyNgn,
  HourglassSimpleMedium,
  CheckCircle,
  Copy,
} from "phosphor-react";
import capitalize from "../../../helpers/capitalize";
import Loader from "../../../components/Loader/Loader";
import { getTransaction } from "../../../services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./TransactionDetails.module.scss";

//components
import Button from "../../../components/Button/Button";

import EthereumLogo from "../../../assets/icons/ethereum.png";
import BinanceLogo from "../../../assets/icons/binance.png";
import BitcoinLogo from "../../../assets/icons/bitcoin.png";
import PolygonLogo from "../../../assets/icons/polygon.png";
import Busd from "../../../assets/icons/busd.png";
import Usdc from "../../../assets/icons/usdc.png";
import BSCNETWORKLogo from "../../../assets/icons/bsc-network.jpg";
import BNBLogo from "../../../assets/icons/bnb.jpg";
import Dai from "../../../assets/icons/dai.jpg";

const TransactionDetails = ({ data: userData }) => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState("initials");
  const [transactionId, setTransactionId] = useState();
  const [transaction, setTransaction] = useState();

  const icons = {
    ETHEREUM_MAINNET: EthereumLogo,
    ETH: EthereumLogo,
    BSC_MAINNET: BSCNETWORKLogo,
    BNB: BNBLogo,
    Bitcoin: BitcoinLogo,
    BTC: BitcoinLogo,
    MATIC_MAINNET: PolygonLogo,
    Polygon: PolygonLogo,
    BUSD: Busd,
    USDC: Usdc,
    DAI: Dai,
  };

  //local_currency,
  //   local_amount,
  //   coin

  //get latest btc value to naira
  const { data, isLoading } = useQuery(
    ["get transaction", transactionId],
    () => getTransaction(transactionId),
    {
      onSuccess: (data) => {
        console.log(data);
        setTransaction(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);

    toast.success("Item copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const id = searchParams.get("id");

    if (id) {
      setTransactionId(id);
      return;
    }
    navigate(-1);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.transactionDetails}>
          <div className={styles.transactionDetails__heading}>
            <div
              onClick={() => navigate(-1)}
              className={styles.transactionDetails__icon}
            >
              <CaretLeft size={25} color={"#000"} weight="bold" />
            </div>
            <h3>Payment Details</h3>
          </div>

          <div className={styles.transactionDetails__amount}>
            {/* <h5 className={styles.transactionDetails__amount__heading}>
              Heading
            </h5> */}
            <h2 className={styles.transactionDetails__amount__naira}>
              {/* <CurrencyNgn weight="bold" color="#5C5C5C" size={32} /> */}
              <span>
                {userData?.currency?.symbol}
                {Number(transaction?.local_amount ?? 0)?.toLocaleString()}
              </span>
            </h2>
            <h4 className={styles.transactionDetails__amount__crypto}>
              {transaction?.coin_amount} {transaction?.coin}
            </h4>
          </div>

          <div className={styles.transactionDetails__status}>
            {transaction?.status === "PENDING" && (
              <HourglassSimpleMedium size={20} color="#4438CB" />
            )}
            {transaction?.status === "FAILED" && (
              <HourglassSimpleMedium color="#E10000" size={20} />
            )}
            {transaction?.status === "SUCCESSFUL" && (
              <CheckCircle size={20} color="#39A648" />
            )}
            <h4
              className={styles.transactionDetails__status}
              style={
                transaction?.status === "SUCCESSFUL"
                  ? { color: "#39A648" }
                  : transaction?.status === "FAILED"
                  ? { color: "#E10000" }
                  : { color: "#4438CB" }
              }
            >
              {capitalize(transaction?.status ?? "")}
            </h4>
          </div>

          <div className={styles.transactionDetails__details}>
            <div className={styles.transactionDetails__detail}>
              <h4>Deposit Wallet</h4>
              <p>Knit Wallet</p>
            </div>
            <div className={styles.transactionDetails__detail}>
              <h4>Network</h4>
              <p>
                <img src={icons[transaction?.network]} />
                <b>{transaction?.network}</b>
              </p>
            </div>
            <div className={styles.transactionDetails__detail}>
              <h4>To Address</h4>
              <p>
                {transaction?.toAddress ? (
                  <span>{`${transaction?.toAddress?.slice(
                    0,
                    9
                  )}...${transaction?.toAddress?.slice(-5)}`}</span>
                ) : (
                  <span> --</span>
                )}

                <div
                  onClick={() => handleCopy(transaction?.toAddress)}
                  className={styles.transactionDetails__detail__copy}
                >
                  <Copy size={25} color="#000" />
                </div>
              </p>
            </div>
            <div className={styles.transactionDetails__detail}>
              <h4>Transaction ID</h4>
              <p>
                <span>{transaction?.hash ?? "- -"}</span>
                <div
                  onClick={() => handleCopy(transaction?.hash)}
                  className={styles.transactionDetails__detail__copy}
                >
                  <Copy size={25} color="#000" />
                </div>
              </p>
            </div>

            <div className={styles.transactionDetails__detail}>
              <h4>Exchange Rate</h4>
              <p>
                {`1${transaction?.coin}  ~ ${
                  transaction?.exchange_rate / transaction?.coin_to_usd
                }${transaction?.local_currency}`}
              </p>
            </div>

            <div className={styles.transactionDetails__detail}>
              <h4>Reference</h4>
              <p>
                <span>{transaction?.id ?? "- -"}</span>
                <div
                  onClick={() => handleCopy(transaction?.id)}
                  className={styles.transactionDetails__detail__copy}
                >
                  <Copy size={25} color="#000" />
                </div>
              </p>
            </div>
            <div className={styles.transactionDetails__detail}>
              <h4>Timestamp</h4>
              <p>{dateFormat(transaction?.updatedAt, "mmm dS, h:MM TT")}</p>
            </div>
          </div>

          <div className={styles.transactionDetails__proceed}>
            <Button onClick={() => navigate(-1)} text="Done" />
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionDetails;
