import React from 'react'
import ReactLoading from 'react-loading';
import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader}>
        <div className={styles.loader__main}>
            <h4>Please wait</h4>
            <ReactLoading type="bubbles" width={32} height={32} color="#4438CB" />
        </div>
    </div>
  )
}

export default Loader