import userEvent from "@testing-library/user-event";
import React, { useState, useRef, useEffect } from "react";
import styles from "./InputPin.module.scss";

const InputPin = ({ value, setValue, ...props }) => {
  const [PinOne, setPinOne] = useState();
  const [PinTwo, setPinTwo] = useState();
  const [PinThree, setPinThree] = useState();
  const [PinFour, setPinFour] = useState();
  const [PinFive, setPinFive] = useState();
  const [PinSix, setPinSix] = useState();

  const PinOneRef = useRef(null);
  const PinTwoRef = useRef(null);
  const PinThreeRef = useRef(null);
  const PinFourRef = useRef(null);
  const PinFiveRef = useRef(null);
  const PinSixRef = useRef(null);

  const refs = [PinOneRef, PinTwoRef, PinThreeRef, PinFourRef, PinFiveRef , PinSixRef];

  const style = {};

  useEffect(() => {
    console.log("called", value[0] , value[1] , value[2] , value[3] , value[4] , value[5])
    setPinOne(value[0] ?? "");
    setPinTwo(value[1] ?? "");
    setPinThree(value[2] ?? "");
    setPinFour(value[3] ?? "");
    setPinFive(value[4] ?? "");
    setPinSix(value[5] ?? "");
  }, [value]);

  return (
    <div className={styles?.inputPin}>
      <input
        {...props}
        ref={PinOneRef}
        value={PinOne}
        type={props?.type ?? "tel"}
        maxLength={1}
        style={style}
      />
      <input
        {...props}
        ref={PinTwoRef}
        value={PinTwo}
        type={props?.type ?? "tel"}
        maxLength={1}
        style={style}
      />
      <input
        {...props}
        ref={PinThreeRef}
        value={PinThree}
        style={style}
        type={props?.type ?? "tel"}
        maxLength={1}
      />
      <input
        {...props}
        ref={PinFourRef}
        value={PinFour}
        type={props?.type ?? "tel"}
        maxLength={1}
        style={style}
      />
      <input
        {...props}
        ref={PinFiveRef}
        value={PinFive}
        type={props?.type ?? "tel"}
        maxLength={1}
        style={style}
      />
       <input
        {...props}
        ref={PinSixRef}
        value={PinSix}
        type={props?.type ?? "tel"}
        maxLength={1}
        style={style}
      />
     
    </div>
  );
};

export default InputPin;
