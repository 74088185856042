import React, { useState , useEffect } from "react";
import {Power } from "phosphor-react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Home.module.scss";

//components
import Button from "../../../components/Button/Button";
import Transaction from "../../../components/Transaction/Transaction";
import Loader from "../../../components/Loader/Loader";

const Home = ({isLoadingUserDetails , data , getUserDetails , setEnabled}) => {
  const navigate = useNavigate();

 

  const handleLogout = () => {
    localStorage.removeItem("knit-token");
    navigate("/auth/login");
  };


  useEffect(() => {
    setEnabled(true)
    getUserDetails();
  }, [])
  
  return (
    <>
      <div className={styles.home}>
        <div className={styles.home__heading}>
          <h2>Merchant {data?.user?.merchant_id ?? 0}</h2>
          <button onClick={handleLogout}>
            <Power size={17} color="#e16c00" />
            <span>Signout</span>
          </button>
        </div>
        <div className={styles.home__balance}>
          <h3 className={styles.home__balance__desc}>
            Total amount spent today
          </h3>
          <h2 className={styles.home__balance__amount}>
            {/* <span>
              <CurrencyNgn size={38} weight="bold" color="#fff" />
            </span> */}
            <span>
              {data?.currency?.symbol}
              {data
                ? Number(
                    data?.metrics[0].totalSuccessfulTransactionAmountToday
                  ).toLocaleString()
                : 0}
              .<b>00</b>
            </span>
          </h2>
        </div>

        <div className={styles.home__transactions}>
          <div className={styles.home__transactions__heading}>
            <h4>Recent transactions</h4>
            <Link to="/dashboard/transactions">
              <button>View all</button>
            </Link>
          </div>

          <div className={styles.home__transactions__items}>
            {data?.transactions && data?.transactions?.length > 0 ? (
              data?.transactions?.map((transaction) => (
                <Transaction data={transaction} currency={data?.currency} />
              ))
            ) : (
              <p className={styles.home__transactions__items__empty}>
                You currently do not have any transaction history. Click the
                button below to get started.
              </p>
            )}
          </div>
        </div>

        <div className={styles.home__makePayment__box}>
          <div className={styles.home__makePayment}>
            <Link
              to={`/dashboard/make-payment?networks=${JSON.stringify(
                data?.networks
              )}&currency=${JSON.stringify(data?.currency)}`}
            >
              <Button text="Make Payment" />
            </Link>
          </div>
        </div>
      </div>
      {isLoadingUserDetails ? <Loader /> : null}
    </>
  );
};

export default Home;
