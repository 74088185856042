import React, { useState } from "react";
import { Backspace } from "phosphor-react";
import styles from "./keyboard.module.scss";

const Keyboard = ({ content, setContent , limit }) => {
  const keyboardItems = [
    {
      number: 1,
      onClick: () => content?.length < limit ? setContent(`${content}1`) : null,
    },
    {
      number: 2,
      onClick: () => content?.length < limit ? setContent(`${content}2`) : null,
    },
    {
      number: 3,
      onClick: () => content?.length < limit ? setContent(`${content}3`) : null,
    },
    {
      number: 4,
      onClick: () => content?.length < limit ? setContent(`${content}4`) : null,
    },
    {
      number: 5,
      onClick: () => content?.length < limit ? setContent(`${content}5`) : null,
    },
    {
      number: 6,
      onClick: () => content?.length < limit ? setContent(`${content}6`) : null,
    },
    {
      number: 7,
      onClick: () => content?.length < limit ? setContent(`${content}7`) : null,
    },
    {
      number: 8,
      onClick: () => content?.length < limit ? setContent(`${content}8`) : null,
    },
    {
      number: 9,
      onClick: () => content?.length < limit ? setContent(`${content}9`) : null,
    },
    {
      number: "",
      onClick: () => {},
    },
    {
      number: 0,
      onClick: () => content?.length < limit ? setContent(`${content}0`) : null,
    },
    {
      label: <Backspace size={32} weight="fill" />,
      onClick: () =>
        content?.length > 0 ? setContent(content.slice(0, -1)) : null,
    },
  ];
  return (
    <>
      <div className={styles.keyboard}>
        {keyboardItems.map((item, index) => (
          <button
            className={styles.keyboard__button}
            onClick={item?.onClick}
            key={index}
          >
            {item?.number ?? item?.label}
          </button>
        ))}
      </div>
    </>
  );
};

export default Keyboard;
