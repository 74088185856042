import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import Guard from "./Guard";
import Layout from "../layout/Layout";

// pages
import Home from "../pages/Dashboard/Home/Home";
import Transactions from "../pages/Dashboard/Transactions/Transactions";
import MakePayment from "../pages/Dashboard/MakePayment/MakePayment";
import VerifyPhone from "../pages/VerifyPhone/VerifyPhone";
import Verify from "../pages/Dashboard/Verify/Verify";
import Pay from "../pages/Dashboard/Pay/Pay";
import TransactionDetails from "../pages/Dashboard/TransactionDetails/TransactionDetails";
import Login from "../pages/Login/Login";
import LandingPage from "../pages/Home/Home";

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    guard: true,
  },
  {
    path: "/dashboard/transactions",
    name: "Transactions",
    component: Transactions,
    guard: true,
  },
  {
    path: "/dashboard/make-payment",
    name: "MakePayment",
    component: MakePayment,
    guard: true,
  },
  {
    path: "/dashboard/pay",
    name: "Pay",
    component: Pay,
    guard: true,
  },
  {
    path: "/dashboard/verify",
    name: "Verify",
    component: Verify,
    guard: true,
  },
  {
    path: "/dashboard/transaction",
    name: "TransactionDetails",
    component: TransactionDetails,
    guard: true,
  },
  {
    path: "/auth/verify-phone",
    name: "VerifyPhone",
    component: VerifyPhone,
    guard: false,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    guard: false,
  },
];
const Routes = () => {
  return (
    <>
      <Switch>
        {/* <Route path="/" element={<LandingPage />} /> */}
        {routes.map((route, index) => {
          return route.guard === false ? (
            <Route
              path={route?.path}
              key={index}
              element={
                <Layout>
                  <route.component />
                </Layout>
              }
            />
          ) : (
            <Route
              path={route?.path}
              key={index}
              element={
                <Guard>
                  <Layout>
                    <route.component />
                  </Layout>
                </Guard>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Switch>
    </>
  );
};

export default Routes;
