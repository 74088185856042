import { useState } from "react";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";

import Month from "../Month/Month";
// import NavButton from "./NavButton";
import DatepickerContext from "../../Contexts/datepickerContext";

function Datepicker({ setOpenDatePicker, setPickerSelected }) {
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isStartDate,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
  });

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
      //   setOpenDatePicker(false)
      setPickerSelected(true);
      console.log(data, "from handle date change");
    } else {
      setState(data);
      setPickerSelected(false);
      console.log(data, "from handle date change else");
    }
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        isStartDate,
      }}
    >
      <div>
        {[activeMonths[0]].map((month) => (
          <Month
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
            goToNextMonths={goToNextMonths}
            goToPreviousMonths={goToPreviousMonths}
          />
        ))}
        {console.log(activeMonths)}
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
