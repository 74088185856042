import { useMonth } from "@datepicker-react/hooks";
import { CaretLeft, CaretRight, CaretDown } from "phosphor-react";
import styles from "./Month.module.scss";
import Day from "../Day/Day";

function Month({ year, month, firstDayOfWeek , goToPreviousMonths, goToNextMonths }) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  return (
    <div className={styles.month}>
      <div className={styles.month__heading}>
        <div className={styles.month__heading__arrow} onClick={goToPreviousMonths}>
          <CaretLeft size={20} weight="bold"  color="#797B86" />
        </div>
        <div className={styles.month__heading__label}>
          <h4>{monthLabel}</h4>
          {/* <div className={styles.month__heading__label__arrow}>
            <CaretDown size={12} weight="bold" color="#797B86" />
          </div> */}
        </div>
        <div className={styles.month__heading__arrow} onClick={goToNextMonths}>
          <CaretRight size={20} weight="bold"  color="#797B86" />
        </div>
      </div>
      <div className={styles.month__days}>
        {weekdayLabels.map((dayLabel) => (
          <h5 key={dayLabel}>{dayLabel}</h5>
        ))}
      </div>
      <div className={styles.month__day__block}>
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
}

export default Month;
