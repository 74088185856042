import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import ReactLoading from "react-loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CaretLeft, CurrencyNgn } from "phosphor-react";
import {
  getCoinValueForLocalCurrency,
  createTransactionInvoice,
  generateLink,
} from "../../../services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./MakePayment.module.scss";

//components
import Keyboard from "../../../components/Keyboard/Keyboard";

//images
import EthereumLogo from "../../../assets/icons/ethereum.png";
import BinanceLogo from "../../../assets/icons/binance.png";
import BitcoinLogo from "../../../assets/icons/bitcoin.png";
import PolygonLogo from "../../../assets/icons/polygon.png";
import BSCNETWORKLogo from "../../../assets/icons/bsc-network.jpg";
import BNBLogo from "../../../assets/icons/bnb.jpg";
import USDT_BSC from "../../../assets/icons/usdt-bsc.jpg";
import USDT_OTHER from "../../../assets/icons/usdt-other.jpg";
import Dai from "../../../assets/icons/dai.jpg";
import Busd from "../../../assets/icons/busd.png";
import Usdc from "../../../assets/icons/usdc.png";

//components
import Select from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import ModalWrapper from "../../../components/ModalWrapper/ModalWrapper";

const MakePayment = ({ data: user }) => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState("initials");
  const [coinOptions, setCoinOptions] = useState();
  const [activeNetwork, setActiveNetwork] = useState();
  const [networks, setNetworks] = useState();
  const [currency, setCurrency] = useState();

  useEffect(() => {
    if (coinOptions) {
      const _networks = [];
      for (let p in coinOptions) {
        console.log(p, "keys");
        if (p === "BTC") {
          _networks.push({
            name: "Bitcoin",
            short: "BTC",
            key: "BTC",
            icon: BitcoinLogo,
          });
        } else if (p === "ETHEREUM_MAINNET") {
          _networks.push({
            name: "Ethereum",
            key: "ETHEREUM_MAINNET",
            short: "ETH",
            icon: EthereumLogo,
          });
        } else if (p === "MATIC_MAINNET") {
          _networks.push({
            name: "Polygon",
            key: "MATIC_MAINNET",
            short: "MATIC",
            icon: PolygonLogo,
          });
        } else if (p === "BSC_MAINNET") {
          _networks.push({
            name: "BSC",
            key: "BSC_MAINNET",
            short: "BSC",
            icon: BSCNETWORKLogo,
          });
        } else {
          _networks.push({
            name: p,
            key: p,
            short: p,
            icon: BitcoinLogo,
          });
        }
      }
      setNetworks(_networks);
      setActiveNetwork(_networks[0]);
    }
  }, [coinOptions]);

  const [coins, setCoins] = useState([]);
  const [activeCoin, setActiveCoin] = useState({});
  const [coinValue, setCoinValue] = useState({ number_of_coins: 0, coin: "" });
  const [remotePayment, setRemotePayment] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log(user);
    if (showModal) {
      setContent("");
    }
  }, [showModal]);

  //local_currency,
  //   local_amount,
  //   coin

  //get latest btc value to naira
  const {} = useQuery(
    ["get latest btc value to naira", activeCoin, content],
    () =>
      getCoinValueForLocalCurrency(
        currency?.identifier,
        Number(content),
        activeCoin?.name ?? "BTC",
        user?.user?.id
      ),
    {
      onSuccess: (data) => {
        console.log(data);
        setCoinValue(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  //createTransactionInvoice
  const { isLoading, mutate } = useMutation(
    ["create transaction invoice", activeCoin],
    (payload) => createTransactionInvoice(payload),
    {
      onSuccess: (data) => {
        console.log(data, remotePayment, "invoice data");

        const payload = {
          amount: content,
          coinAmount: data?.coin_amount,
          coinToUsd: data?.coin_to_usd,
          localCurrency: data?.local_currency,
          address: data?.toAddress,
          exchangeRate: data?.exchange_rate,
          coinValue: coinValue,
          activeCoin: activeCoin?.name,
          activeCoinShort: activeCoin?.short,
          activeNetwork: activeNetwork?.name,
          id: data?.id,
        };

        navigate(`/dashboard/pay?payload=${JSON.stringify(payload)}`);
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  //createTransactionInvoice
  const {
    isLoading: isGeneratingLink,
    mutate: _generateLink,
    data,
  } = useMutation(["create link"], (payload) => generateLink(payload), {
    onSuccess: (data) => {
      console.log(data, remotePayment, "invoice data");
      setShowModal(true);
    },
    onError: (error) => {
      console.log({ error });
      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  const handleCreateTransactionInvoice = () => {
    if (remotePayment) {
      _generateLink({
        local_amount: Number(content ?? 0),
        local_currency: user?.currency?.identifier,
      });
      return;
    }
    mutate({
      local_amount: Number(content ?? 0),
      local_currency: user?.currency?.identifier,
      coin: activeCoin?.name,
      network: activeNetwork?.key,
    });
  };

  useEffect(() => {
    const networks = searchParams.get("networks");
    const _currency = searchParams.get("currency");

    if (networks && _currency) {
      try {
        setCoinOptions(JSON.parse(networks));
        setCurrency(JSON.parse(_currency));
        console.log(JSON.parse(networks));
      } catch (error) {
        navigate("/dashboard");
      }
      return;
    }
    navigate("/dashboard");
  }, []);

  useEffect(() => {
    if (coinOptions && activeNetwork) {
      const _coins = [];
      coinOptions[activeNetwork?.key].tokens?.forEach((item) => {
        if (item === "BTC") {
          _coins.push({ name: "BTC", short: "BTC", icon: BitcoinLogo });
        } else if (item === "ETH") {
          _coins.push({
            name: "ETH",
            short: "ETH",
            icon: EthereumLogo,
          });
        } else if (item === "BUSD") {
          _coins.push({
            name: "BUSD",
            short: "BUSD",
            icon: Busd,
          });
        } else if (item === "USDC") {
          _coins.push({
            name: "USDC",
            short: "USDC",
            icon: Usdc,
          });
        } else if (item === "BNB") {
          _coins.push({
            name: "BNB",
            short: "BNB",
            icon: BNBLogo,
          });
        } else if (item === "DAI") {
          _coins.push({
            name: "DAI",
            short: "DAI",
            icon: Dai,
          });
        } else if (item === "MATIC") {
          _coins.push({
            name: "MATIC",
            short: "MATIC",
            icon: PolygonLogo,
          });
        } else if (item === "USDT" && activeNetwork?.key === "BSC_MAINNET") {
          _coins.push({
            name: "USDT",
            short: "USDT_BSC_MAINNET",
            icon: USDT_BSC,
          });
        } else if (item === "USDT" && activeNetwork?.key !== "BSC_MAINNET") {
          console.log(activeNetwork?.key);
          _coins.push({
            name: "USDT",
            short: "USDT",
            icon: USDT_OTHER,
          });
        } else {
          _coins.push({
            name: item,
            short: item,
            icon: EthereumLogo,
          });
        }
      });
      setCoins(_coins);
      setActiveCoin(_coins[0]);
    }
  }, [activeNetwork, coinOptions]);

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);

    toast.success("Link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <>
      {paymentStatus === "initials" && (
        <div className={styles.makePayment}>
          <div className={styles.makePayment__heading}>
            <div
              onClick={() => navigate(-1)}
              className={styles.makePayment__icon}
            >
              <CaretLeft size={25} color={"#000"} weight="bold" />
            </div>
            <h3>Make Payment</h3>
          </div>
          <div className={styles.makePayment__toggleNew}>
            <button
              onClick={() => setRemotePayment(false)}
              className={
                remotePayment
                  ? `${styles.makePayment__toggleNew__button}`
                  : `${styles.makePayment__toggleNew__button} ${styles.makePayment__toggleNew__button__active}`
              }
            >
              In-Person
            </button>
            <button
              onClick={() => setRemotePayment(true)}
              className={
                remotePayment
                  ? `${styles.makePayment__toggleNew__button} ${styles.makePayment__toggleNew__button__active}`
                  : `${styles.makePayment__toggleNew__button}`
              }
            >
              Remote Payment
            </button>
          </div>

          <Select
            active={activeNetwork}
            setActive={setActiveNetwork}
            options={networks}
            isRemote={remotePayment}
            heading="Network"
          />
          <Select
            heading="Coin"
            active={activeCoin}
            setActive={setActiveCoin}
            isRemote={remotePayment}
            options={coins ? coins : []}
          />

          {/* <div className={styles.makePayment__remotePayment}>
            <div
              onClick={() => setRemotePayment(!remotePayment)}
              className={
                remotePayment
                  ? `${styles.makePayment__remotePayment__toggle} ${styles.makePayment__remotePayment__toggle__active}`
                  : `${styles.makePayment__remotePayment__toggle} ${styles.makePayment__remotePayment__toggle__inActive}`
              }
            >
              <div
                className={
                  remotePayment
                    ? `${styles.makePayment__remotePayment__toggle__ball} ${styles.makePayment__remotePayment__toggle__ball__active}`
                    : `${styles.makePayment__remotePayment__toggle__ball} ${styles.makePayment__remotePayment__toggle__ball__inActive}`
                }
              ></div>
            </div>
            <p>Remote payment</p>
          </div> */}

          <div className={styles.makePayment__amount}>
            <h2 className={styles.makePayment__amount__naira}>
              {/* <CurrencyNgn weight="bold" color="#5C5C5C" size={32} /> */}
              <span>
                {currency?.symbol}
                {content && Number(content) >= 1
                  ? Number(content)?.toLocaleString()
                  : "0.00"}
              </span>
            </h2>
            {!remotePayment && (
              <h4 className={styles.makePayment__amount__crypto}>
                {coinValue.number_of_coins ?? 0} {coinValue?.coin}
              </h4>
            )}
          </div>

          <div>
            <Keyboard content={content} setContent={setContent} limit={9} />
          </div>
          <div
            // onClick={() => setPaymentStatus("pay")}
            className={styles.makePayment__proceed}
          >
            <Button
              onClick={handleCreateTransactionInvoice}
              text={
                isLoading || isGeneratingLink ? (
                  <ReactLoading type="spin" width={32} height={32} />
                ) : (
                  "Proceed"
                )
              }
            />
          </div>
        </div>
      )}
      {showModal && (
        <ModalWrapper showModal={showModal} setShowModal={setShowModal}>
          <div className={styles.link}>
            <h4 className={styles.link__heading}>Use link to proceed</h4>
            <div className={styles.link__box}>
              <div className={styles.link__item}>
                <p>
                  <span>{`https://link.useknit.io/${data?.reference}`}</span>
                </p>
              </div>
              <button
                className={styles.link__button}
                onClick={() =>
                  handleCopy(`https://link.useknit.io/${data?.reference}`)
                }
              >
                Copy
              </button>
            </div>
          </div>
        </ModalWrapper>
      )}

      {/* {paymentStatus === "pay" && (
        <Pay amount={content} setPaymentStatus={setPaymentStatus} />
      )}
      {paymentStatus === "verify" && (
        <Verify setPaymentStatus={setPaymentStatus} />
      )}
      {paymentStatus === "success" && (
        <Success amount={content} setPaymentStatus={setPaymentStatus} />
      )}
      {paymentStatus === "failed" && (
        <Failed amount={content} setPaymentStatus={setPaymentStatus} />
      )} */}
    </>
  );
};

export default MakePayment;
