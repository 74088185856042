import React, { useState , useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { userLogin } from "../../services";
import styles from "./Login.module.scss";

//components
import Button from "../../components/Button/Button";

//images
import Logo from '../../assets/images/logo-mobile.png'

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate()

  const { isLoading, mutate: _userLogin } = useMutation(
    ["user login"],
    (payload) => userLogin(payload),
    {
      onSuccess: (data) => {
        setErrorMessage("");
       // localStorage.setItem("knit-token", data?.access_token)
        navigate(`/auth/verify-phone?otp_identifier=${data?.otp_identifier}`);
        console.log(data)
      },
      onError: (error) => {
        console.log({error})
        setErrorMessage(error?.response?.data?.message ?? "An error occurred");
      },
    }
  );

  const handleSubmission = () => {
    if (!formData?.username) {
      setErrorMessage("Please enter a valid username");
      return;
    }
    if (!formData?.password) {
      setErrorMessage("Please enter a valid password number");
      return;
    }
    setErrorMessage("");
    _userLogin({password:  formData?.password, username: formData?.username});
  };


  useEffect(() => {
    const token = localStorage.getItem("knit-token");
    if(token){
      navigate("/dashboard")
    }
  }, [])
  
  return (
    <div className={styles.login}>
      <div className={styles.login__logo}>
        <img src={Logo} alt="knit logo" />
      </div>
      <div className={styles.login__main}>
        <div className={styles.login__form}>
          <div>
            <label htmlFor="username">Username</label>
            <input
              id="username"
              type="username"
              value={formData?.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </div>

          <div>
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={formData?.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </div>
          <p className={styles.login__form__error}>{errorMessage}</p>

          <div className={styles.login__form__submit}>
            <Button
              onClick={handleSubmission}
              text={
                isLoading ? (
                  <ReactLoading type="spin" width={32} height={32} />
                ) : (
                  "Sign in"
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
