import React from "react";
import { Navigate, Route } from "react-router-dom";
//import cookie from '../utils/cookie'

export default function Guard({ children}) {

 const token = localStorage.getItem('knit-token');


  return token ? children : <Navigate to="/auth/login" />;
}
