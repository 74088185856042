import React from "react";
import { CurrencyNgn, PaperPlaneTilt } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import capitalize from "../../helpers/capitalize";
import styles from "./Transaction.module.scss";

const Transaction = ({ data, currency }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        navigate(`/dashboard/transaction?id=${data?.id}` ?? "/dashboard")
      }
      className={styles.transaction}
    >
      <div className={styles.transaction__icon__box}>
        {/* <img src={ReceivedIcon} alt="icon for a transaction"/> */}
        <div className={styles.transaction__icon}>
          <PaperPlaneTilt size={20} color="#474747" weight="fill" />
        </div>
      </div>

      <div className={styles.transaction__desc}>
        <h4
          style={
            data?.status === "SUCCESSFUL"
              ? { border: "1px solid #39A648", color: "#39A648" }
              : data?.status === "FAILED"
              ? { border: "1px solid #E10000", color: "#E10000" }
              : { border: "1px solid #4438CB", color: "#4438CB" }
          }
        >
          {capitalize(data?.status ?? "")}
        </h4>
        {/* Jan 25, 00:02 */}
        <p>{dateFormat(data?.updatedAt, "mmm dS, h:MM TT")}</p>
      </div>

      <div className={styles.transaction__amount}>
        <span>+</span>
        {/* <span>
          <CurrencyNgn size={15} weight="fill" color="#000" />
        </span> */}
        
        <span>{currency?.symbol}{Number(data?.local_amount).toLocaleString()}</span>
      </div>
    </div>
  );
};

export default Transaction;
